import React from 'react';

import barCode from '../../assets/aboutSectionAssets/icons/barCode.svg';
import IconVertical from '../../assets/aboutSectionAssets/icons/iconVertical';
import IconSquare from '../../assets/aboutSectionAssets/icons/iconMinD';

import WorldIcon from '../../assets/aboutSectionAssets/icons/worldIcon';
import IconMinD from '../../assets/aboutSectionAssets/icons/world3D.png';

import styles from './AboutUsSection.module.scss';
const InfoBlockWithDots = () => {
  return (
    <section className={styles.containerAbout}>
      <article className={styles.textBlock}>
        <div className={styles.column}>
          <p className={styles.textOne}>
            AIXLAND Metaverse is an innovative, digital platform that unites the
            physical world of real estate and infrastructure with the
            flexibility and limitless possibilities of digital space.
          </p>
        </div>
        <div className={styles.column}>
          <p className={styles.textBlockTwo}>
            The platform overcomes spatial and temporal barriers in real estate management,
            enabling precise, real-time
            monitoring and analysis of operational processes.
            Access detailed property status anytime, from anywhere.
          </p>
        </div>
      </article>
      <div className={styles.infoContainer}>
        <div className={styles.barcodeBlock}>
          <div className={styles.sectionIndustrial}>
            aixland industrial metaverse
          </div>
          <img className={styles.barCodeClass} src={barCode} alt="" />
        </div>
        <div className={styles.iconsContainer}>
          <div className={styles.iconWorld}>
            <WorldIcon />
          </div>
          <div className={styles.iconVerticalBlock}>
            <IconVertical />
          </div>
          <div className={styles.iconSquare}>
            <IconSquare />
          </div>
          <div className={styles.iconMin3D }>
            <img className={styles.icon3D} src={IconMinD} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoBlockWithDots;
