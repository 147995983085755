import image1 from '../assets/carouselAssets/image/image1.png';
import image2 from '../assets/carouselAssets/image/image2.png';
import image3 from '../assets/carouselAssets/image/image3.png';
import image4 from '../assets/carouselAssets/image/image4.png';
import image5 from '../assets/carouselAssets/image/image5.png';
import image6 from '../assets/carouselAssets/image/image6.png';
import image7 from '../assets/carouselAssets/image/image7.png';
import image8 from '../assets/carouselAssets/image/image8.png';
import image9 from '../assets/carouselAssets/image/image9.png';
import image10 from '../assets/carouselAssets/image/image10.png';
import image11 from '../assets/carouselAssets/image/image11.png';
import image12 from '../assets/carouselAssets/image/Image12.webp';
import image13 from '../assets/carouselAssets/image/image2.png';
import image14 from '../assets/carouselAssets/image/image3.png';
import image15 from '../assets/carouselAssets/image/image4.png';

export const images = [
  {
    id: 1,
    src: image1,
    title: 'offices',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 2,
    src: image2,
    title: 'Shop',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 3,
    src: image3,
    title: 'Shopping center',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 4,
    src: image4,
    title: 'Bank',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 5,
    src: image5,
    title: 'Hospital',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 6,
    src: image6,
    title: 'Hotel',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 7,
    src: image7,
    title: 'University',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 8,
    src: image8,
    title: 'Private houses',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 9,
    src: image9,
    title: ' industrial buildings',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 10,
    src: image10,
    title: 'Restaurants',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 11,
    src: image11,
    title: 'multi-storey buildings',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
  {
    id: 12,
    src: image12,
    title: 'Warehouse',
    subtitle:
      'Overcome spatial and temporal constraints in real estate management. Leverage advanced digital monitoring and real-time analysis of operational processes.',
  },
];
