import React from 'react';
import styles from './index.module.scss';

const Group2087330262 = () => {
  return (
    <div>
      <svg className={styles.iconFill}   width="186" height="160" viewBox="0 0 186 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bi_2673_916)">
          <path
            d="M0.900391 81.5512L47.2032 1.62891L139.087 1.62891L179.984 72.2925C183.105 77.6836 183.105 84.3315 179.984 89.7226L144.078 151.762C140.986 157.104 135.295 160.408 129.122 160.445L46.5703 160.93L0.900391 81.5512Z"
            fill="white" fillOpacity="0.1" />
          <path
            d="M46.8837 160.384L1.52819 81.5522L47.5165 2.1726L138.773 2.1726L179.514 72.5649C182.537 77.7875 182.537 84.2276 179.514 89.4502L143.607 151.49C140.612 156.665 135.099 159.866 129.119 159.901L46.8837 160.384Z"
            stroke="url(#paint0_linear_2673_916)" strokeOpacity="0.1" strokeWidth="1.08738" />
        </g>
        <path
          d="M137.363 2.17476C137.363 3.37584 138.337 4.34951 139.538 4.34951C140.739 4.34951 141.713 3.37584 141.713 2.17476C141.713 0.973672 140.739 0 139.538 0C138.337 0 137.363 0.973672 137.363 2.17476Z"
          fill="#AEAEAE" />
        <circle cx="2.17476" cy="2.17476" r="2.17476" transform="matrix(-1 0 0 1 50.1934 158.215)" fill="#AEAEAE" />
        <circle cx="92.3741" cy="80.6886" r="38.6019" stroke="white" strokeOpacity="0.1" strokeWidth="1.08738"
                strokeDasharray="2.17 2.17" />
        <path
          d="M93.5119 75.4235C94.3371 75.4235 95.1236 75.596 95.8386 75.9063C95.8922 74.7396 96.2944 73.6607 96.9395 72.7675C96.4853 72.6726 96.0168 72.6221 95.537 72.6221H90.9917C90.5129 72.6221 90.0444 72.6721 89.5938 72.766C90.2812 73.7199 90.6921 74.884 90.6987 76.1446C91.5342 75.6853 92.4927 75.4235 93.5119 75.4235Z"
          fill="white" />
        <path
          d="M93.2651 72.2567C96.2236 72.2567 98.6219 69.8583 98.6219 66.8998C98.6219 63.9413 96.2236 61.543 93.2651 61.543C90.3065 61.543 87.9082 63.9413 87.9082 66.8998C87.9082 69.8583 90.3065 72.2567 93.2651 72.2567Z"
          fill="white" />
        <path
          d="M101.678 81.3969C104.404 81.3969 106.613 79.187 106.613 76.4617C106.613 73.7353 104.404 71.5264 101.678 71.5264C98.9769 71.5264 96.7869 73.696 96.7461 76.3871C98.3022 77.363 99.3934 79.0135 99.5924 80.9294C100.226 81.2265 100.931 81.3969 101.678 81.3969Z"
          fill="white" />
        <path
          d="M84.8518 71.5263C82.1249 71.5263 79.916 73.7357 79.916 76.4616C79.916 79.1875 82.1249 81.3964 84.8518 81.3964C85.8174 81.3964 86.7152 81.1142 87.4766 80.6349C87.7191 79.0574 88.5647 77.6778 89.7728 76.7387C89.7779 76.6464 89.7866 76.555 89.7866 76.4616C89.7866 73.7357 87.5767 71.5263 84.8518 71.5263Z"
          fill="white" />
        <path
          d="M104.883 71.4208C106.439 72.3966 107.53 74.0472 107.729 75.9636C108.362 76.2601 109.067 76.4311 109.814 76.4311C112.54 76.4311 114.749 74.2217 114.749 71.4958C114.749 68.7699 112.54 66.5605 109.814 66.5605C107.114 66.56 104.924 68.7301 104.883 71.4208Z"
          fill="white" />
        <path
          d="M93.512 86.4927C96.2379 86.4927 98.4478 84.2829 98.4478 81.5569C98.4478 78.8309 96.2379 76.6211 93.512 76.6211C90.786 76.6211 88.5762 78.8309 88.5762 81.5569C88.5762 84.2829 90.786 86.4927 93.512 86.4927Z"
          fill="white" />
        <path
          d="M103.772 81.7334H99.6159C99.5705 83.3962 98.8611 84.8936 97.7383 85.97C100.835 86.8917 103.102 89.7641 103.102 93.157V94.7351C107.205 94.5835 109.57 93.4219 109.726 93.3423L110.056 93.1749H110.09V88.0528C110.091 84.568 107.256 81.7334 103.772 81.7334Z"
          fill="white" />
        <path
          d="M111.91 76.7676H107.754C107.709 78.4304 106.999 79.9278 105.877 81.0042C108.974 81.9259 111.24 84.7978 111.24 88.1907V89.7688C115.343 89.6177 117.708 88.4561 117.864 88.376L118.194 88.2091H118.229V83.087C118.229 79.6027 115.394 76.7676 111.91 76.7676Z"
          fill="white" />
        <path
          d="M89.284 85.97C88.1668 84.8987 87.4595 83.4095 87.4074 81.7574C87.2538 81.7462 87.1012 81.7334 86.944 81.7334H82.7574C79.2726 81.7334 76.4375 84.5685 76.4375 88.0533V93.1759L76.4503 93.255L76.8029 93.3663C79.4706 94.1997 81.852 94.5825 83.9195 94.704V93.157C83.9206 89.7646 86.1861 86.8922 89.284 85.97Z"
          fill="white" />
        <path
          d="M95.6057 86.8291H91.4176C87.9328 86.8291 85.0977 89.6652 85.0977 93.1485V98.2711L85.1109 98.3512L85.4631 98.4615C88.7887 99.5001 91.6779 99.8471 94.0552 99.8471C98.7001 99.8471 101.392 98.5227 101.558 98.4385L101.888 98.2711H101.923V93.1485C101.924 89.6642 99.0895 86.8291 95.6057 86.8291Z"
          fill="white" />
        <path
          d="M76.6428 76.5889C77.3895 76.5889 78.0943 76.4179 78.7282 76.1214C78.9272 74.2049 80.0179 72.5539 81.574 71.578C81.5332 68.8879 79.3432 66.7178 76.6428 66.7178C73.9159 66.7178 71.707 68.9272 71.707 71.6536C71.707 74.3795 73.9159 76.5889 76.6428 76.5889Z"
          fill="white" />
        <path
          d="M80.5805 81.1624C79.4587 80.086 78.7488 78.5881 78.7039 76.9248H74.5479C71.0631 76.9253 68.2285 79.7604 68.2285 83.2447V88.3673H68.2637L68.5934 88.5342C68.7496 88.6128 71.1142 89.7759 75.2175 89.9265V88.3484C75.2175 84.956 77.4831 82.0836 80.5805 81.1624Z"
          fill="white" />
        <path
          d="M82.4148 71.0384C83.0068 70.7985 83.9102 70.5041 84.7727 70.5041C85.7766 70.5041 86.7585 70.7623 87.6343 71.2538C87.8293 71.0624 88.0079 70.8542 88.1702 70.6311C87.3383 69.5384 86.8841 68.1992 86.8841 66.8217C86.8841 65.8939 87.0913 64.9732 87.4863 64.1367C86.5871 63.3242 85.4397 62.8807 84.2205 62.8807C82.0014 62.8807 80.0696 64.3827 79.5041 66.4982C81.1618 67.4628 82.2418 69.149 82.4148 71.0384Z"
          fill="white" />
        <path
          d="M98.4004 70.3523C98.5719 70.6248 98.7725 70.8815 99.0001 71.1204C99.8105 70.7162 100.702 70.5038 101.599 70.5038C102.481 70.5038 103.327 70.7294 103.881 70.9183C104.055 68.8701 105.293 67.0624 107.148 66.1463C106.468 64.2043 104.627 62.8789 102.544 62.8789C101.196 62.8789 99.9203 63.4321 98.9991 64.4059C99.3226 65.1776 99.4865 65.9886 99.4865 66.82C99.4859 68.0934 99.1113 69.307 98.4004 70.3523Z"
          fill="white" />
        <defs>
          <filter id="filter0_bi_2673_916" x="-53.4685" y="-52.74" width="290.162" height="268.039"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1845" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2673_916" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2673_916" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.08738" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2673_916" />
          </filter>
          <linearGradient id="paint0_linear_2673_916" x1="2.86941" y1="1.6289" x2="121.451" y2="255.107"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>


    </div>
  );
};

export default Group2087330262;