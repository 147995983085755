import React, { useEffect, useRef, useState} from 'react';

// Вынести из utils в компоненты

const LazyImage = React.memo(
  ({ src, alt, placeholder, errorImage, index, id }) => {
    const imgRef = useRef();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setLoaded(true);
            observer.disconnect();
          }
        },
        {
          threshold: 0.1,
        },
      );

      if (imgRef.current) {
        observer.observe(imgRef.current);
      }

      return () => observer.disconnect();
    }, []);
    return (
      <div>
        {!loaded && <img src={placeholder} alt="placeholder" />}
        <img
          ref={imgRef}
          src={loaded && !error ? src : undefined}
          alt={alt}
          id={id}
          onError={() => setError(true)}
        />
        {error && <img src={errorImage} alt="error" />}
      </div>
    );
  },
);
export default LazyImage;