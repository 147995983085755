import React, { useState } from 'react';

import { images } from '../../../constants/Images';

import styles from './ListImage.module.scss';

const ListImage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <div className={`${styles.containerImagesCategory}`}>
        {images.map((image, index) => {
          const isActiveClass =
            index === activeIndex ? styles.centralSlide : styles.otherSlide;

          return (
            <div
              className={` ${styles.imagesContainer} ${isActiveClass}`}
              key={image.id}
            >
              <img index={index} src={image.src} alt={image.alt} />
              <div className={`${styles.titleListCategory}`}>{image.title}</div>
              <div className={`${styles.subtitleCategory}`}>
                {image.subtitle}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListImage;
