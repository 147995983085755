import React, { useCallback, useEffect, useRef, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import LogoWhite from '../../../assets/headerAsseets/LogoWhite';

import BtnHeader from './BtnHeader';
import styles from './Header.module.scss';

gsap.registerPlugin(ScrollToPlugin);

const Header = ({ refs, openModal }) => {
  const [lastScrollY, setLastScrollY] = useState(0); // Последняя позиция скролла
  const [showHeader, setShowHeader] = useState(true);

  const animatedRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sections = [
    { name: 'Who we are', ref: refs.whoAreRef },
    { name: 'Save Earth', ref: refs.greenRef },
    { name: 'Technology', ref: refs.technologylRef },
    { name: 'problem', ref: refs.problemsRef },
    { name: 'direction', ref: refs.sliderCarouselRef, },

  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [displayText, setDisplayText] = useState(
    sections.map(section => section.name),
  );
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY < lastScrollY) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  const scrollTo = useCallback(ref => {
    if (!ref.current) return;

    const targetPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;

    gsap.to(window, {
      duration: 1, // Продолжительность анимации
      scrollTo: {
        y: targetPosition,
        offsetY: 90,
      },
      ease: 'power2.out', // Плавная анимация
    });
  }, []);
  const resetText = index => {
    setDisplayText(prevText =>
      prevText.map((text, i) => (i === index ? sections[i].name : text)),
    );
  };
  useEffect(() => {
    if (hoveredIndex === null) return;

    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const fixedText = sections[hoveredIndex].name;

    let iterations = 0;
    const interval = setInterval(() => {
      setDisplayText(prevText =>
        prevText.map((text, index) =>
          index === hoveredIndex
            ? text
                .split('')
                .map((char, i) => {
                  if (i < iterations) {
                    return fixedText[i];
                  }
                  return letters[Math.floor(Math.random() * 26)];
                })
                .join('')
            : text,
        ),
      );
      iterations += 1 / 2;

      if (iterations >= fixedText.length) {
        clearInterval(interval);
      }
    }, 30); // частота обновления текста

    return () => clearInterval(interval); // очистка интервала при снятии эффекта
  }, [hoveredIndex]);
  useEffect(() => {
    // Устанавливаем начальный размер 0
    gsap.set(animatedRef.current, {
      scale: 0,
    });

    // Запускаем анимацию увеличения размера
    gsap.to(animatedRef.current, {
      scale: 1,
      duration: 1.5, // продолжительность анимации
      ease: 'power3.inOut', // тип плавности
    });
  }, []);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false)
  }
  useEffect(() => {
    // Отключение прокрутки при открытом меню
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Очистка при размонтировании компонента
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  return (
    <>
      <header
        ref={animatedRef}
        className={`${styles.header} ${showHeader ? styles.headerVisible : ''}`}
      >
        <div className="flex justify-between items-center p-5">
          <div className="w-25">
            <a href="#" className={styles['logo-link']}>
              <LogoWhite />
            </a>
          </div>

          {/* Основное навигационное меню (скрываем, когда меню открыто) */}
          {!isMenuOpen && (
            <div>
              <nav className={styles.navHeader}>
                <ul>
                  {sections.map((item, index) => (
                    <li
                      key={index}
                      className="inline-block ml-6"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => {
                        setHoveredIndex(null);
                        resetText(index);
                      }}
                    >
                      <a
                        onClick={() => {
                          scrollTo(item.ref);
                          setIsMenuOpen(false); // Закрываем меню при клике на ссылку
                        }}
                        className={styles.navHeaderLink}
                      >
                        {displayText[index]}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
             </div>
          )}

          {/* Кнопка и иконка меню */}
          <BtnHeader openModal={openModal} />
          <div className={`${styles.menuIcon}`} onClick={toggleMenu}>
            <MenuIcon />
          </div>
        </div>

        {/* Выпадающее меню с логотипом и кнопкой */}
        {isMenuOpen && (
          <div onClick={toggleMenu} className={`${styles.mobileMenuContent}`}>
            <div className={styles.logoMobile}>
              <a href="#" className={styles['logo-link']}>
                <LogoWhite />
              </a>
            </div>
            <ul className={styles.mobileMenuLinks}>
              {sections.map((item, index) => (
                <li key={index} className="navMobile">
                  <a
                    onClick={() => {
                      scrollTo(item.ref);
                      setIsMenuOpen(false);
                    }}
                    className={styles.navHeaderLink}
                  >
                    {displayText[index]}
                  </a>
                </li>
              ))}
            </ul>

            <BtnHeader openModal={openModal} />
          </div>
        )}
        <div className={styles.borderBottomHeader}></div>
      </header>
    </>
  );
};

export default Header;
