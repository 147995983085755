import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className={styles.footerTitle}>All rights reserved</div>
        <div className={styles.footerTitle}>Privacy Policy</div>
        <div className={styles.footerTitle}>2024 AIXLAND</div>
    </footer>
  );
};

export default Footer;
