import React, { useState } from 'react';

import styles from './ModalWindow.module.scss';

const ModalWindow = ({ isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false);

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <>
      {isOpen && (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
          <div>

          </div>
          <div
            className={styles.modalContainer}
            onClick={e => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={closeModal}>
              X
            </button>
            <h2 className={styles.titleModal}>Contact Us</h2>
            <p className={styles.modalSubtitle}>
              Leave your details. We will answer all <br /> your questions.
            </p>
            <form className={styles.modalForm}>
              <input
                type="text"
                placeholder="Name"
                className={styles.modalInput}
              />
              <input
                type="text"
                placeholder="Phone number"
                className={styles.modalInput}
              />
              <input
                type="email"
                placeholder="Email"
                className={styles.modalInput}
              />
              <textarea
                placeholder="Message"
                className={styles.modalTextarea}
              ></textarea>
              <p className={styles.modalSubtitleBotton}>
                I agree with the processing of my personal data and the public
                offer agreement
              </p>
              <div className={styles.modalSubmit}>
                <div>
                  <button className={styles.button}>SEND</button>
                  <div
                    className={styles.dot}
                    style={{ top: '10px', left: '10px' }}
                  ></div>
                  <div
                    className={styles.dot}
                    style={{ top: '10px', right: '10px' }}
                  ></div>
                  <div
                    className={styles.dot}
                    style={{ bottom: '10px', left: '10px' }}
                  ></div>
                  <div
                    className={styles.dot}
                    style={{ bottom: '10px', right: '10px' }}
                  ></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalWindow;
