import React, { useEffect, useState } from 'react';
import './BorderLine.scss'
import styles from './Technology.module.scss';

const BorderDirect = ({hovered}) => {
  const [hasEnter, setHasEnter] = useState(hovered);
  useEffect(() => {
    if(hovered) {
      setHasEnter(hovered);
    }
  },[hovered]);

  return (
    <div
      className="neon-line-container"
    >
      <svg fill="none" className={styles.svgBorderDirect} viewBox=" 0 0 10 120">
        <defs>
          {/* Определение градиента для линии */}
          <linearGradient id="neonGradient" x1="100%" y1="100%" x2="0%" y2="0%">
            <stop offset="0%" stopColor="#21B48C" />
            <stop offset="100%" stopColor="#000" />
          </linearGradient>
        </defs>

        {/* Путь с градиентом и анимацией */}
        <path
          id="neonPath"
          d="M 50 50 H 200 A 10 10 0 0 1 210 60 V 250"
          className={`neon-line ${hovered ? 'hovered' : hasEnter ? 'notHovered' : ''}`}
          stroke="url(#neonGradient)"
        />

      </svg>
    </div>
  );
};

export default BorderDirect;