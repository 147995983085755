import React from 'react';
import Header from './Header/Header';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/Footer/Footer';

const Layout = ({refs,openModal}) => {
  return (
    <div>
      <Header refs={refs} openModal={openModal} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;