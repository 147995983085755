import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import videoWorldMin from '../../assets/assetsGreenEnergy/video/countPlanet.webm';
import videoWorldMinHvc from '../../assets/assetsGreenEnergy/video/countPlanet-1.mov';

import styles from './GreenEnergyOverview.module.scss';
import SplitType from 'split-type';

gsap.registerPlugin(ScrollTrigger);

const data = [
  { number: 65, text: 'Smart, Real-Time Property Management' },
  { number: 65, text: 'Overcoming Physical and Temporal Limits' },
  { number: 65, text: 'Eco-efficient resource use through monitoring' },
];

const GreenEnergyOverview = ({ greenRef }) => {
  const greenEnergyRef = useRef(null);
  const greenAnimated = useRef(null);
  const textAnimation = useRef();

  useEffect(() => {
    // Анимация появления блока с GSAP и ScrollTrigger
    gsap.fromTo(
      greenEnergyRef.current,
      { opacity: 0, x: 1200 }, // Начальные параметры
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: greenEnergyRef.current,
          start: 'top 80%', // Настройка начала анимации (когда элемент будет на 80% экрана)
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);
  useEffect(() => {
    // Анимация появления блока с GSAP и ScrollTrigger
    gsap.fromTo(
      greenAnimated.current,
      { opacity: 0, x: -1200 }, // Начальные параметры
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: greenAnimated.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);
  useEffect(() => {
    const splitInstance = new SplitType(textAnimation.current, { types: 'chars' });

    gsap.from(splitInstance.chars, {
      duration: 1.5,
      opacity: 0,
      stagger: 0.06,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: textAnimation.current,
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
    });

    // Очищаем SplitType при размонтировании
    return () => splitInstance.revert();
  }, []);

  const isIOS = () => {
    return (
      /iPhone|iPad|iPod/i.test(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };


  return (
    <div className={styles.videoBackground}>
      <div ref={greenRef} className={styles.containerGrEn}>
        <div ref={textAnimation} className={styles.titleGreenEn}>
          How we save the <br /> earth
        </div>
        <div ref={greenAnimated} className={styles.wrapperGreen}>
          {data.map((item, index) => (
            <div key={index} className={styles.dugaGrEng}>
              <video
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
                className={styles.minWorldGreen}
              >
                {isIOS() ? (
                  <source src={videoWorldMinHvc} type="video/quicktime" />
                ) : (
                  <source src={videoWorldMin} type="video/webm" />
                )}
                Ваш браузер не поддерживает видео.
                <source src={videoWorldMin} type="video/mp4" />
                Ваш браузер не поддерживает тег видео.
              </video>
              <div className={styles.numbersGrEng}>{item.number}</div>
              <button type="button">
                <div className={styles.btnGrEng}>
                  <div className={styles.btnEnText} >{item.text}</div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GreenEnergyOverview;
