import React, { useState } from 'react';

import styles from './Technology.module.scss';

const CardTechnology = ({ item,index, onMouseEnter, onMouseLeave, hovered }) => {
  const isIOS = () => {
    return (
      /iPhone|iPad|iPod/i.test(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };
  const {Animation, ImageRomb} = item
   return (
    <div {...(index === 3 && {onMouseEnter:() => onMouseEnter(index),onMouseLeave: () => onMouseLeave(index) })}  className={styles.technologyBorder}>
      {ImageRomb && (
        <div className={styles.imageRomb}>
          <ImageRomb hovered={hovered} />
        </div>
      )}
      <div className={styles.animationBorder}>
        {Animation && <Animation hovered={hovered}/>}
      </div>
      <img className={styles.imageRomb} src={item.imageRomb} alt="" />
      <div className={item.completed ? styles.imageLogoWrapper : null}>
        {item.imageLogo && (
          <img className={styles.imageLogo} src={item.imageLogo} alt="" />
        )}
        {item.videoWorld && (
          <video
            playsInline
            muted
            autoPlay
            loop
            preload="metadata"
            className={styles.imageWorld}
          >
            {isIOS() ? (
              <source src={item.videoWorldMow} type="video/quicktime" />
            ) : (
              <source src={item.videoWorld} type="video/webm" />
            )}
            Ваш браузер не поддерживает видео.
          </video>
        )}
        {item.subText && <p className={styles.subText}>{item.subText}</p>}
      </div>
      <div className={styles.titleTechnology}>{item.title}</div>
      <div className={styles.textTechnology}>{item.text}</div>
    </div>
  );
};

export default CardTechnology;