import React, { memo } from 'react';

import styles from './Header.module.scss';
const dots = [
  { class: `${styles.dot} ${styles['top-left']}` },
  { class: `${styles.dot} ${styles['top-right']}` },
  { class: `${styles.dot} ${styles['bottom-left']}` },
  { class: `${styles.dot} ${styles['bottom-right']}` },
];
const BtnHeader = ({ openModal }) => {
  return (
    <div className={styles.btnHeader}>
      <div className={styles.buttonContainer}>
        <button onClick={openModal} className={styles.button}>
          CONTACT US
        </button>
        {dots.map((dot, index) => (
          <div key={index} className={dot.class}></div>
        ))}
      </div>
    </div>
  );
};

export default memo(BtnHeader);
