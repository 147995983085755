import React, { useEffect, useRef, useState } from 'react';
import HeaderShowcase from '../Components/HeaderShowcase/HeaderShowcase';
import AboutUsSection from '../Components/AboutUsSection/AboutUsSection';
import GreenEnergyOverview from '../Components/GreenEnergyOverview/GreenEnergyOverview';
import DigitalTwinInfo from '../Components/DigitalTwinInfo/DigitalTwinInfo';
import AudienceProfiles from '../Components/AudienceProfiles/AudienceProfiles';
import ProblemsWeSolve from '../Components/ProblemsWeSolve/ProblemsWeSolve';
import ChallengesWeSolve from '../Components/ChallengesWeSolve/ChallengesWeSolve';
import SliderCarousel from '../Components/SliderCarousel/SliderCarousel';

import changingVideos from '../assets/appAssets/video/green2Building.mp4';

// import mainVideo from '../assets/listDestinationsAssets/video/MainScrenn2mbn.mp4';
import mainVideo from '../assets/video/GreenMainScreen2.mp4';
import ModalWindow from '../Components/modalWindow/ModalWindow';
import Technology from '../Components/Technology/Technology';
import audienceProfiles from '../Components/AudienceProfiles/AudienceProfiles';

const HomePage = ({headerShowcaseRef,
                    aboutUsRef,
                    greenRef,
                    digitalTwinInfoRef,
                    audienceProfilesRef,
                    technologylRef,
                    problemsRef,
                    sliderCarouselRef}) => {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [currentBackgroundVideo, setCurrentBackgroundVideo] =
    useState(mainVideo);
  const lastScrollY = useRef(window.scrollY);


  useEffect(() => {
    const thresholdEnter = 0.3;
    const thresholdExit = 0.3;

    const observerEnter = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (
            entry.isIntersecting &&
            entry.intersectionRatio > thresholdEnter
          ) {
            switch (entry.target) {
              case digitalTwinInfoRef.current:
                setCurrentBackgroundVideo(changingVideos);
                break;
            }
          }
        });
      },
      { threshold: thresholdEnter },
    );
    const observerExit = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (
            !entry.isIntersecting &&
            entry.intersectionRatio < thresholdExit
          ) {
            switch (entry.target) {
              case digitalTwinInfoRef.current:
                setCurrentBackgroundVideo(mainVideo);
                break;
            }
          }
        });
      },
      { threshold: thresholdExit },
    );
    if (digitalTwinInfoRef.current) {
      observerEnter.observe(digitalTwinInfoRef.current);
      observerExit.observe(digitalTwinInfoRef.current);
    }



    return () => {
      observerEnter.disconnect();
      observerExit.disconnect();
    };
  }, [scrollDirection]);
  // const [currentIndex, setCurrentIndex] = useState(0);
  //
  // const scrollToIndex = (index) => {
  //   if (sections[index] && sections[index].current) {
  //     sections[index].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  //
  // const handleWheel = (event) => {
  //   if (event.deltaY > 0) {
  //     setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, sections.length - 1));
  //   } else {
  //     setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  //   }
  // };
  //
  // useEffect(() => {
  //   scrollToIndex(currentIndex);
  // }, [currentIndex]);
  //
  // useEffect(() => {
  //   window.addEventListener('wheel', handleWheel);
  //   return () => {
  //     window.removeEventListener('wheel', handleWheel);
  //   };
  // }, []);
  return (
    <div>
      <div className="video-blur-overlay"></div>
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        className="globalBackgroundVideo fade-video"
       >
        <source src={currentBackgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <HeaderShowcase headerShowcaseRef={headerShowcaseRef} />
      <AboutUsSection aboutUsRef={aboutUsRef} />
      <GreenEnergyOverview greenRef={greenRef} />
      <Technology technologylRef={technologylRef}/>
      <DigitalTwinInfo digitalTwinInfoRef={digitalTwinInfoRef} />
      <ProblemsWeSolve problemsRef={problemsRef} />
      <AudienceProfiles  problemsRef={problemsRef} />
      <SliderCarousel sliderCarouselRef={sliderCarouselRef} />
    </div>
  );
};

export default HomePage;