import React from 'react';
import styles from './index.module.scss';

const Group2087330265 = () => {
  return (
    <div>
      <svg   className={styles.iconWhiteFill} viewBox="0 0 158 136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-1.01585e-05 67.9996L39.2792 3.43217e-06L117.838 0L157.117 67.9996L117.838 135.999H39.2792L-1.01585e-05 67.9996Z"
          fill="white" />
        <g clipPath="url(#clip0_2649_796)">
          <path
            d="M99.5768 69.5264H95.5273L100.29 62.9238H94.9256C94.0414 62.9238 93.2155 63.3428 92.7069 64.046L89.6921 68.2267L86.6772 64.046C86.1717 63.3438 85.3417 62.9238 84.4616 62.9238H78.9006L79.3202 63.4914L82.9644 68.4272C83.472 69.1175 84.2917 69.5264 85.1626 69.5264H88.7547L84.609 75.2752C84.4739 75.4627 84.1894 75.4627 84.0543 75.2752L83.3932 74.3564L75.9553 64.047C75.4508 63.3438 74.6208 62.9238 73.7397 62.9238H68.3711L77.0851 75.0068C77.5927 75.7101 78.4196 76.129 79.3038 76.129H89.3595C90.2396 76.129 91.0695 75.7101 91.574 75.0068L94.7793 70.5619L98.0786 75.0298C98.5862 75.7201 99.4059 76.129 100.277 76.129H105.744L101.791 70.6487C101.287 69.9454 100.457 69.5264 99.5768 69.5264Z"
            fill="black" />
          <path
            d="M65.0856 64.046C64.5811 63.3428 63.7512 62.9238 62.8711 62.9238H57.5024L62.2652 69.5274H58.2147C57.3346 69.5274 56.5046 69.9464 56.0001 70.6496L52.0479 76.13H57.5147C58.3846 76.13 59.2043 75.7211 59.7129 75.0308L63.0123 70.5619L66.2175 75.0058C66.7251 75.7091 67.552 76.128 68.4362 76.128H73.7976L73.1846 75.2762L65.0856 64.046Z"
            fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_2649_796">
            <rect width="53.6017" height="13.2062" fill="white" transform="translate(52.0488 62.9238)" />
          </clipPath>
        </defs>
      </svg>

    </div>
  );
};

export default Group2087330265;