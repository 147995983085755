 import Group2087330261 from '../assets/AudienceAssets/icons/Group 2087330261';
import Group2087330260 from '../assets/AudienceAssets/icons/Group 2087330260';
import Group2087330265 from '../assets/AudienceAssets/icons/Group 2087330265';
import Group2087330263 from '../assets/AudienceAssets/icons/Group 2087330263';
import Group2087330262 from '../assets/AudienceAssets/icons/Group 2087330262';

import image1 from '../assets/AudienceAssets/img/download (12).webp';
import image2 from '../assets/AudienceAssets/img/download (16).webp'
import image3 from '../assets/AudienceAssets/img/download (14).webp'
import image4 from '../assets/AudienceAssets/img/download (13).webp'
import image5 from '../assets/AudienceAssets/img/download (15).webp'
import image6 from '../assets/AudienceAssets/img/download (17).webp'
import Frame2131329794 from '../assets/AudienceAssets/icons/Frame 2131329794';
 import Group20873302632 from '../assets/AudienceAssets/icons/Group 2087330263 (2)';


export const functionality = [
  {
    id: 1,
    title: 'Low Operational Efficiency',
    text: 'AIXLAND leverages smart contracts and IoT sensors to streamline operations, reducing manual tasks and lowering management costs.',
    image: <Frame2131329794/>,
    value: image1,
   },
  {
    id: 2,
    title: 'Insufficient Data Security',
    text: ' Blockchain technology ensures robust data protection and enhances security across all property transactions and records.',
    image: <Group2087330261/>,
    value: image2,
   },
  {
    id: 3,
    title: 'Barriers to Investment',
    text: 'The platform offers straightforward access to the global real estate market and transparent insights into asset conditions.',
    image: <Group2087330260/>,
    value: image3,
   },
  {
    id: 7,
    text: 'Tokenization for Decentralized Real Estate Ownership.',
    image: <Group2087330265/>,
   },
  {
    id: 4,
    title: 'Complexity in Facility Management and Maintenance',
    text: 'Digital twins and IoT sensors provide instant, real-time insights into asset conditions, facilitating efficient planning and execution of repairs.',
    image: <Group2087330263/>,
    value: image4,
    },
  {
    id: 5,
    title: 'Limited Access to Data and Analytics',
    text: 'AIXLAND integrates IoT and AI for precise analytics and forecasts, empowering informed, data-driven decision-making with real-time information.',
    image: <Group2087330262/>,
    value: image5,
   },
  {
    id: 6,
    title: 'Staff Shortages',
    text: 'Automated processes for property management, monitoring, and maintenance reduce reliance on a large workforce by handling tasks autonomously.',
    image: <Group20873302632/>,
    value: image6,
  },
];
