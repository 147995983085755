import React from 'react';

import arrowLeft from '../../assets/listDestinationsAssets/arrow-left.png';
import videoList from '../../assets/listDestinationsAssets/video/MainScrenn2mbn.mp4';

import styles from './ListDestinations.module.scss';
import ListImage from './ListImage/ListImage';

const categories = [
  'Hotels',
  'Hospitals',
  'Private houses',
  'Multi-storey buildings',
  'Industrial buildings',
  'Shopping centers',
  'Offices',
  'Universities',
  'Restaurants',
  'Banks',
  'Shops',
];

const ListDestinations = () => {
  return (
    <section className={styles.containerList}>
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        className={`${styles.globalBackgroundVideo} ${styles.blurEffect}`}
      >
        <source src={videoList} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <a className={`${styles.homePageLink}`} href="/">
        <img className={`${styles.arrowLeftIcon}`} src={arrowLeft} alt="" />
      </a>
      <div className={`${styles.titleListContainer}`}>
        <div className={`${styles.containerListTitle}`}>
          Digital Twins for All <br /> Building Types
        </div>
        <ul className={`${styles.containerCategory}`}>
          {categories.map((item, index) => (
            <li key={index} className={`${styles.listCategory}`}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <ListImage />
    </section>
  );
};

export default ListDestinations;
