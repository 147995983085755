import React, { useEffect, useRef } from 'react';

import gsap from 'gsap';

import imageRomb from '../../assets/problemsAssets/image/HexagonReady01.png';

import styles from './ProblemsWeSolve.module.scss';

import SplitType from 'split-type';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


const cardRombik = [
  {
    id: 1,
    image: imageRomb,
    title: 'Enhanced Cost Efficiency',
    text: 'Reduce costs and boost efficiency with AIXLAND’s automation, minimizing tasks to lower expenses and speed transactions, setting a new standard in property management.',
  },
  {
    id: 2,
    image: imageRomb,
    title: 'Decentralized real Estate Tokenization',
    text: 'Revolutionize investments with AIXLAND\'s tokenization, enabling fractional real estate ownership, making high-value assets accessible and fostering global decentralization.',
  },
  {
    id: 3,
    image: imageRomb,
    title: 'Accelerated Deal Closures',
    text: 'Close deals faster with AIXLAND’s smart contracts, automating transactions for unmatched security, transparency, and speed.',
  },
  {
    id: 4,
    image: imageRomb,
    title: 'Web 3.0 Marketplace for Real Estate Services',
    text: 'Discover AIXLAND’s Web 3.0 marketplace, a blockchain-powered platform connecting property managers, investors, and providers in a secure, decentralized ecosystem.',
  },
  {
    id: 5,
    image: imageRomb,
    title: 'Real-Time Monitoring with Digital Twins and IoT',
    text: 'Stay ahead with AIXLAND’s real-time monitoring. Digital twins and IoT deliver live insights for proactive management and peak asset performance anywhere.',
  },
  {
    id: 6,
    image: imageRomb,
    title: 'Automation of Processes via Smart Contracts',
    text: 'Streamline operations with AIXLAND\'s smart contracts, automating processes to ensure fast, accurate, and secure transactions, leading in operational excellence.',
  },
  {
    id: 7,
    image: imageRomb,
    title: 'Advanced Analytics and Forecasting with AI',
    text: 'Make data-driven decisions with AIXLAND’s AI analytics, offering predictive insights to forecast trends, mitigate risks, and maximize investment returns.',
  },
];

const ProblemsWeSolve = ({ problemsRef }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const headerRef = useRef(null);

  const leftAnimation = useRef();
  const rightAnimation = useRef();
  useEffect(() => {
    // Анимация появления блока с GSAP и ScrollTrigger
    gsap.fromTo(
      rightAnimation.current,
      { opacity: 0, x: 2000 }, // Начальные параметры
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: 'power3.inOut',
        scrollTrigger: {
          trigger: rightAnimation.current,
          start: 'top 99%',
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);
  useEffect(() => {
    const splitInstance = new SplitType(headerRef.current, { types: 'chars' });

    gsap.from(splitInstance.chars, {
      duration: 1.5,
      opacity: 0,
      stagger: 0.07,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: headerRef.current,
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
    });

    // Очищаем SplitType при размонтировании
    return () => splitInstance.revert();
  }, []);
  return (
    <section ref={problemsRef} className={`${styles.wrapperRombContent}`}>
      <div ref={headerRef} className={styles.titleProblems}>
        advantages
      </div>
      <div ref={rightAnimation} className={`${styles.contentBlockProblems}`}>
        {cardRombik.map((card, index) => (
          <article
            className={`${styles.cardBlock} ${isVisible ? styles.visibleProblem : ''}`}
            key={index}
          >
            <figcaption className={styles.subCartImage}>
              <div
                className={` ${styles.cardIdRombik}`}
              >
                {card.id}
              </div>
            </figcaption>
            <div
              className={`${styles.cardRombTextTitle}`}
            >
              <h3 className={styles.titleCardRombik}>{card.title}</h3>
              <p className={`${styles.textCardRombik} text-[#424344] mt-4`}>
                {card.text}
              </p>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default ProblemsWeSolve;
