import React from 'react';

import styles from './iconAbout.module.scss';

const WorldIcon = () => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.iconAbout}
    >
      <rect width="63" height="63" rx="31.5" fill="white" fillOpacity="0.5" />
      <path
        d="M32 42C37.5228 42 42 37.5228 42 32C42 26.4772 37.5228 22 32 22C26.4772 22 22 26.4772 22 32C22 37.5228 26.4772 42 32 42Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 32H42"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 22C34.5013 24.7384 35.9228 28.292 36 32C35.9228 35.708 34.5013 39.2616 32 42C29.4987 39.2616 28.0772 35.708 28 32C28.0772 28.292 29.4987 24.7384 32 22Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WorldIcon;
