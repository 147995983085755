import React, { useEffect, useRef } from 'react';

import gsap from 'gsap';

import videoDouble from '../../../assets/digitalAssets/video/DigitalBuild (4).webm';
import videoDoubleIos from '../../../assets/digitalAssets/video/DDDigitalBuild-1.mov';

import styles from './DigitalDoubleFeatures.module.scss';
import SplitType from 'split-type';
// import videoDouble from "../../../assets/video/hexagonAnim-1.mov";



const DigitalDoubleFeatures = () => {
  const rightAnimation = useRef(null);
  const leftAnimation = useRef(null);
  const textAnimation = useRef(null);
  useEffect(() => {
    // Анимация появления блока с GSAP и ScrollTrigger
    gsap.fromTo(
      rightAnimation.current,
      { opacity: 0, x: -1200 }, // Начальные параметры
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: rightAnimation.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);
  useEffect(() => {
    // Анимация появления блока с GSAP и ScrollTrigger
    gsap.fromTo(
      leftAnimation.current,
      { opacity: 0, x: 1200 }, // Начальные параметры
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: leftAnimation.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);
  useEffect(() => {
    const splitInstance = new SplitType(textAnimation.current, { types: 'chars' });

    gsap.from(splitInstance.chars, {
      duration: 1.5,
      opacity: 0,
      stagger: 0.06,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: textAnimation.current,
        start: 'top 85%',
        toggleActions: 'play none none none',
      },
    });

    // Очищаем SplitType при размонтировании
    return () => splitInstance.revert();
  }, []);
  const isIOS = () => {
    return (
      /iPhone|iPad|iPod/i.test(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };
  return (
    <>
      <div ref={rightAnimation} className={styles.doubleContainer}>
        <div ref={textAnimation} className={styles.titleDigital}>
          Advantages of a <br/> Digital Double for <br/> Solar Panel <br/> Installation
        </div>
        <div className={styles.containerText}>
          <p className={styles.firstParagraph}>
            Unlike traditional 2D mapping solutions that only provide flat images of roofs,
            the AIXLAND configurator enables the placement of solar panels on an accurate 3D model of the building.

            This significant advantage allows for a better understanding of the project,
            leading to more informed decision-making for clients.
          </p>
          <p className={styles.secondParagraph}>
            AIXLAND offers a versatile approach to creating 3D models of buildings based on
            the available input data. This flexibility ensures that the models are relevant and accurately reflect the specifics of each project.
            <span>
              AIXLAND addresses the complexity of typical interfaces by
              providing a modern, user-friendly UX/UI design. This makes the system accessible
              and easy to use, even for individuals without technical training.
            </span>
          </p>
        </div>
      </div>
      <div ref={leftAnimation} className={styles.doubleVideoContainer}>
        <video
          playsInline
          muted
          autoPlay
          loop
          preload="metadata"
          className={styles.videoDouble}
        >
          {isIOS() ? (
            <source src={videoDoubleIos} type="video/quicktime" />
          ) : (
            <source src={videoDouble} type="video/webm" />
          )}
          Ваш браузер не поддерживает видео.
        </video>
      </div>
    </>
  );
};

export default DigitalDoubleFeatures;
