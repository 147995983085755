import React, {  useState } from 'react';
import './AudienceProfiles.scss';

import gsap from 'gsap';

import { functionality } from '../../constants/Functionality';
import BorderLine from '../Technology/BorderLine';

 const AudienceProfiles = ({problemsRef}) => {
   const [wordData, setWordData] = useState(functionality[0]);
   const [val, setVal] = useState(6);
   const handleClick = index => {
     setVal(index);
     setWordData(functionality[index]);
   };

   return (
     <div ref={problemsRef} className="containerAudienceProfiles">
       <div className="titleAudience">
         What problems do we solve
       </div>
       <div className="main">
         <div className="wrapperIconsBlock">
           <div className="containerIcons">
             <div className="flex_row">
               {functionality.map((data, i) => (
                 <div className={`thumbnail icon-${i + 1}`} key={data.id}>
                   <div
                     className={val === i ? "clicked" : ""}
                     onClick={() => handleClick(i)}
                   >
                     {data.image}
                    </div>

                   {val === i && (
                     <div className={`containerImageBlock containerImageBlock-${i}`}>
                       <div className="imageFlex">
                         <img className={`imageProfiles imageProfiles-${i}`} src={data.value} alt="Profile" />
                       </div>
                       <div className={`subTitleVerticalSoty subTitleVerticalSoty-${i}`}>
                         <div className="activeTitle">{data.title}</div>
                         <p className="activeText"> {data.text}</p>
                       </div>
                     </div>
                   )}
                 </div>
               ))}
             </div>
           </div>
         </div>
         <div className="wrapperImageBlock">

         </div>
       </div>

     </div>
   );
 };

export default AudienceProfiles;
