import React from 'react';
import styles from './index.module.scss';

const Frame2131329794 = () => {
  return (
    <div>
      <svg className={styles.iconFill} width="186" height="160" viewBox="0 0 185 161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bi_2673_834)">
          <path
            d="M4.92553 88.5589C1.89571 83.206 1.91751 76.6511 4.98288 71.3185L40.928 8.78676C44.0497 3.35605 49.8498 0.0227255 56.1137 0.0594996L138.589 0.543694L184.53 80.466L137.229 160.932H45.8897L4.92553 88.5589Z"
            fill="white" fillOpacity="0.1" />
          <path
            d="M5.39868 88.2911C2.46354 83.1055 2.48467 76.7554 5.45424 71.5894L41.3993 9.05771C44.4235 3.79671 50.0424 0.567555 56.1105 0.60318L138.273 1.08554L183.902 80.463L136.918 160.388H46.2067L5.39868 88.2911Z"
            stroke="url(#paint0_linear_2673_834)" strokeOpacity="0.1" strokeWidth="1.08738" />
        </g>
        <circle cx="92.1827" cy="80.1456" r="38.6019" stroke="white" strokeOpacity="0.1" strokeWidth="1.08738"
                strokeDasharray="2.17 2.17" />
        <path
          d="M78.9843 65.7622C78.9843 64.7244 79.8239 63.8754 80.871 63.8754H103.201C104.248 63.8754 105.088 64.7244 105.088 65.7622V69.432H107.286V62.4225C107.286 61.8565 106.824 61.3942 106.258 61.3942H89.8144C88.6918 61.3942 87.6823 60.7716 87.1729 59.781L86.2767 58.0263C86.088 57.6678 85.7201 57.4414 85.3145 57.4414H77.8522C77.2767 57.4414 76.8145 57.9037 76.8145 58.4697V69.432H78.9843V65.7622Z"
          fill="white" />
        <path d="M80.8711 65.7617H103.201V69.4315H80.8711V65.7617Z" fill="white" />
        <path
          d="M108.862 71.3184H75.2115C74.5783 71.3184 74.0431 71.8791 74.1454 72.5542L76.5888 89.0825C76.6643 89.6202 77.1171 90.0164 77.6548 90.0164H80.8247V82.724C80.8247 81.2051 82.0227 79.9598 83.5605 79.9032C86.4662 79.7617 88.6831 79.1014 90.2964 77.8749C90.8246 77.4882 91.419 77.29 92.0416 77.29C92.6548 77.29 93.2492 77.4881 93.7586 77.8561C95.3907 79.1014 97.6077 79.7617 100.542 79.9127C102.042 79.9598 103.24 81.2051 103.24 82.724V90.0164H106.419C106.947 90.0164 107.41 89.6202 107.485 89.0919L109.928 72.5542C110.029 71.8872 109.505 71.3184 108.862 71.3184Z"
          fill="white" />
        <path
          d="M100.447 81.7938C97.3996 81.6429 94.7204 80.9542 92.6354 79.3788C92.2769 79.1146 91.7958 79.1146 91.4374 79.3788C89.3524 80.9542 86.6732 81.6429 83.626 81.7938C83.1166 81.8127 82.7109 82.2278 82.7109 82.7278V90.2373C82.7109 93.5957 84.0411 96.8694 86.5222 99.1429C88.6449 101.096 90.8336 102.133 91.6732 102.483C91.9091 102.586 92.1637 102.586 92.3996 102.483C93.2109 102.143 95.2581 101.181 97.3147 99.3599C97.3336 99.3504 97.343 99.3222 97.3619 99.3127C99.9467 97.0392 101.352 93.6807 101.352 90.2278V82.7278C101.352 82.2278 100.956 81.8127 100.447 81.7938ZM93.5189 90.7236C93.325 90.8852 93.2281 91.1276 93.2604 91.378C93.3654 92.0729 93.5674 93.4464 93.6967 94.3271C93.7613 94.7634 93.422 95.1512 92.9776 95.1512H91.095C90.6507 95.1512 90.3114 94.7634 90.3759 94.319L90.8042 91.378C90.8446 91.1357 90.7557 90.8852 90.5618 90.7317C90.0366 90.3034 89.7054 89.649 89.7054 88.9218C89.7054 87.3867 91.2001 86.1828 92.7918 86.708C93.4786 86.9342 94.028 87.4917 94.2461 88.1704C94.5693 89.1804 94.2138 90.15 93.5189 90.7236Z"
          fill="white" />
        <defs>
          <filter id="filter0_bi_2673_834" x="-51.701" y="-54.3103" width="290.601" height="269.611"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1845" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2673_834" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2673_834" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.08738" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2673_834" />
          </filter>
          <linearGradient id="paint0_linear_2673_834" x1="1.64508" y1="-4.54564e-06" x2="121.561" y2="254.586"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Frame2131329794;