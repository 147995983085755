import React from 'react';
import styles from './Star.module.scss';
const Star123 = ({hovered}) => {
  return (
      <svg className={styles.starRomb} width="54" height="45" viewBox="0 0 51 45" fill={hovered ? '#A4FFA7' : 'none'} xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bi_2781_9812)">
          <path
            d="M1.20534 24.7718C0.457443 23.4572 0.45341 21.8467 1.19471 20.5283L11.5016 2.19762C12.2648 0.840139 13.7011 1.0926e-06 15.2584 1.02304e-06L35.6598 1.11803e-07C37.2075 4.26742e-08 38.6363 0.829852 39.4033 2.17415L49.7873 20.3745C50.5394 21.6927 50.5428 23.3092 49.7962 24.6305L39.5244 42.8102C38.7599 44.1633 37.3261 45 35.772 45H15.2196C13.6699 45 12.2396 44.1681 11.4733 42.8212L1.20534 24.7718Z"
            fill="white" fillOpacity="0.1" />
          <path
            d="M1.45396 24.6304C0.755698 23.403 0.751933 21.8994 1.44403 20.6685L11.7509 2.33781C12.4635 1.07042 13.8044 0.286037 15.2584 0.286037L35.6598 0.286036C37.1047 0.286036 38.4388 1.06081 39.1549 2.3159L49.5389 20.5163C50.241 21.7469 50.2442 23.2562 49.5472 24.4898L39.2754 42.6695C38.5616 43.9328 37.223 44.714 35.772 44.714H15.2196C13.7728 44.714 12.4373 43.9373 11.7219 42.6797L1.45396 24.6304Z"
            stroke="url(#paint0_linear_2781_9812)" strokeOpacity="0.1" strokeWidth="0.572072" />
        </g>
        <defs>
          <filter id="filter0_bi_2781_9812" x="-27.963" y="-28.6036" width="106.92" height="102.207"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="14.3018" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2781_9812" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2781_9812" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="0.572072" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2781_9812" />
          </filter>
          <linearGradient id="paint0_linear_2781_9812" x1="0.469347" y1="-1.89404e-06" x2="34.5978" y2="71.2188"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>

  );
};

export default Star123;