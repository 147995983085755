import React from 'react';
import styles from './index.module.scss';

const Group2087330260 = () => {
  return (
    <div>
      <svg className={styles.iconFill}  width="186" height="160" viewBox="0 0 186 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bi_2673_820)">
          <path
            d="M5.04397 90.2655C1.92378 84.8744 1.92378 78.2266 5.04397 72.8355L45.9417 2.17188L137.825 2.17188L185.037 82.6379L138.823 162.56L55.7702 161.102C49.671 160.995 44.0734 157.701 41.0177 152.421L5.04397 90.2655Z"
            fill="white" fillOpacity="0.1" />
          <path
            d="M5.51453 89.9932C2.49185 84.7706 2.49184 78.3305 5.51453 73.1078L46.2553 2.71557L137.514 2.71556L184.408 82.6399L138.513 162.011L55.7797 160.558C49.8711 160.454 44.4485 157.264 41.4883 152.149L5.51453 89.9932Z"
            stroke="url(#paint0_linear_2673_820)" strokeOpacity="0.1" strokeWidth="1.08738" />
        </g>
        <circle cx="91.3097" cy="81.2316" r="38.6019" stroke="white" strokeOpacity="0.1" strokeWidth="1.08738"
                strokeDasharray="2.17 2.17" />
        <path
          d="M66.1755 77.8692C66.0669 78.7431 66.7484 79.5147 67.6291 79.5147H73.5886C74.304 79.5147 74.9147 78.998 75.0332 78.2924C76.1801 71.4619 81.5399 66.102 88.3705 64.9551C89.076 64.8367 89.5927 64.2259 89.5927 63.5105V57.5509C89.5927 56.6703 88.8213 55.9887 87.9472 56.0973C82.4521 56.7802 77.2609 59.3212 73.33 63.2519C69.3993 67.1828 66.8584 72.374 66.1755 77.8692Z"
          fill="white" />
        <path
          d="M100.498 94.2385C97.7174 96.2236 94.4573 97.2729 91.0699 97.2729C83.0991 97.2729 76.3548 91.5609 75.0333 83.691C74.9148 82.9855 74.3041 82.4688 73.5887 82.4688H67.6291C66.7484 82.4688 66.0669 83.2402 66.1755 84.1142C67.7322 96.6401 78.4344 106.086 91.0699 106.086C96.6988 106.086 102.022 104.259 106.464 100.803C107.16 100.262 107.223 99.2336 106.6 98.6106L102.385 94.3948C101.878 93.8886 101.08 93.8225 100.498 94.2385Z"
          fill="white" />
        <path
          d="M93.7692 64.9551C101.639 66.2766 107.351 73.021 107.351 80.9918C107.351 84.3792 106.302 87.6391 104.317 90.4195C103.901 91.0021 103.967 91.8002 104.473 92.3064L108.689 96.5224C109.312 97.1454 110.34 97.0814 110.881 96.3862C114.337 91.9442 116.164 86.6208 116.164 80.9918C116.164 68.3563 106.718 57.6541 94.1925 56.0973C93.3186 55.9887 92.5469 56.6703 92.5469 57.5509V63.5105C92.547 64.2259 93.0637 64.8367 93.7692 64.9551Z"
          fill="white" />
        <path
          d="M92.0461 70.9258H90.0918C89.2828 70.9258 88.627 71.5816 88.627 72.3906V89.4803H93.5109V72.3906C93.5109 71.5816 92.8551 70.9258 92.0461 70.9258Z"
          fill="white" />
        <path
          d="M96.4395 89.4804H99.8586C100.668 89.4804 101.323 88.8245 101.323 88.0155V76.2969C101.323 75.4879 100.668 74.832 99.8586 74.832H96.4395V89.4804Z"
          fill="white" />
        <path
          d="M85.6967 78.7382H82.2812C81.4723 78.7382 80.8164 79.394 80.8164 80.203V88.0154C80.8164 88.8244 81.4723 89.4803 82.2812 89.4803H85.6967V78.7382Z"
          fill="white" />
        <circle cx="2.17476" cy="2.17476" r="2.17476" transform="matrix(-1 0 0 1 47.8438 0)" fill="#AEAEAE" />
        <defs>
          <filter id="filter0_bi_2673_820" x="-51.6658" y="-52.1971" width="291.072" height="269.127"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1845" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2673_820" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2673_820" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.08738" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2673_820" />
          </filter>
          <linearGradient id="paint0_linear_2673_820" x1="1.60797" y1="2.17187" x2="120.19" y2="255.65"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Group2087330260;