import React from 'react';

import DigitalTwinContent from './DigitalTwinContent/DigitalTwinContent';
import DigitalDoubleFeatures from './DigitalDoubleFeatures/DigitalDoubleFeatures';
import styles from './DigitalTwinInfo.module.scss';

const DigitalTwinInfo = ({ digitalTwinInfoRef }) => {
  return (
    <section ref={digitalTwinInfoRef} className={styles.videoBackground}>
      <div className={styles.videoContent}>
        <DigitalTwinContent />
      </div>
      <div className={styles.videoContent}>
        <DigitalDoubleFeatures />
      </div>
    </section>
  );
};

export default DigitalTwinInfo;
