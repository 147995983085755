import React from 'react';

import styles from './iconAbout.module.scss';

const IconVertical = () => {
  return (
    <div>
      <svg
        width="77"
        height="15"
        viewBox="0 0 77 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.iconAbout}
      >
        <path
          d="M71.359 14.5L66 14.5L71.641 0.5L77 0.5L71.359 14.5Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M26.359 14.5L21 14.5L26.641 0.5L32 0.5L26.359 14.5Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M12.359 14.5L7 14.5L12.641 0.5L18 0.5L12.359 14.5Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M64.359 14.5L59 14.5L64.641 0.5L70 0.5L64.359 14.5Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M19.359 14.5L14 14.5L19.641 0.5L25 0.5L19.359 14.5Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M5.35897 14.5L-5.06639e-07 14.5L5.64103 0.5L11 0.5L5.35897 14.5Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M57.1176 14.5L28 14.5L33.8824 0.499999L63 0.500002L57.1176 14.5Z"
          fill="white"
          fillOpacity="0.5"
        />
      </svg>
    </div>
  );
};

export default IconVertical;
