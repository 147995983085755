import React, { useEffect, useRef } from 'react';

import gsap from 'gsap';

import styles from './DigitalTwinContent.module.scss';
import videoDoubleIos from '../../../assets/digitalAssets/video/green22Building-1.mov';
import videoDouble from '../../../assets/digitalAssets/video/green2Building.webm';
import SplitType from 'split-type';

const DigitalTwinContent = () => {
  const rightAnimation = useRef(null);
  const leftAnimation = useRef(null);
  const textAnimation = useRef(null);
  useEffect(() => {
    // Анимация появления блока с GSAP и ScrollTrigger
    gsap.fromTo(
      rightAnimation.current,
      { opacity: 0, x: -1200 }, // Начальные параметры
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: rightAnimation.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);
  useEffect(() => {
    // Анимация появления блока с GSAP и ScrollTrigger
    gsap.fromTo(
      leftAnimation.current,
      { opacity: 0, x: 1200 }, // Начальные параметры
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: leftAnimation.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      },
    );
  }, []);
  useEffect(() => {
    const splitInstance = new SplitType(textAnimation.current, { types: 'chars' });

    gsap.from(splitInstance.chars, {
      duration: 1.5,
      opacity: 0,
      stagger: 0.06,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: textAnimation.current,
        start: 'top 75%',
        toggleActions: 'play none none none',
      },
    });

    // Очищаем SplitType при размонтировании
    return () => splitInstance.revert();
  }, []);
  const isIOS = () => {
    return (
      /iPhone|iPad|iPod/i.test(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };
  return (
    <>
      <div ref={rightAnimation} className={styles.doubleContainer}>
        <div ref={textAnimation} className={styles.titleDigital}>
          what is a digital <br/> double
        </div>
        <div className={styles.containerText}>
          <p className={styles.firstParagraph}>
            A digital double is a virtual representation
            of a physical asset that enables real-time
            interaction and management, enhancing
            decision-making and operational efficiency in real estate.
          </p>
          <p className={styles.secondParagraph}>
            The platform eliminates spatial and temporal barriers in real estate management,
            enabling real-time monitoring and analysis of operational processes for improved efficiency and decision making.
          </p>
        </div>
      </div>
      <div ref={leftAnimation} className={styles.doubleVideoContainer}>
        <video
          playsInline
          muted
          autoPlay
          loop
          preload="metadata"
          className={styles.videoDouble}
        >
          {isIOS() ? (
            <source src={videoDoubleIos} type="video/quicktime" />
          ) : (
            <source src={videoDouble} type="video/webm" />
          )}
          Ваш браузер не поддерживает видео.
        </video>
      </div>
    </>
  );
};

export default DigitalTwinContent;
