import React from 'react';
import styles from './iconAbout.module.scss';

const IconMinD = () => {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.iconAbout}
     >
      <rect width="63" height="63" rx="31.5" fill="white" fillOpacity="0.5" />
      <path
        d="M40 35.9979V27.9979C39.9996 27.6472 39.9071 27.3028 39.7315 26.9991C39.556 26.6955 39.3037 26.4433 39 26.2679L32 22.2679C31.696 22.0924 31.3511 22 31 22C30.6489 22 30.304 22.0924 30 22.2679L23 26.2679C22.6963 26.4433 22.444 26.6955 22.2685 26.9991C22.0929 27.3028 22.0004 27.6472 22 27.9979V35.9979C22.0004 36.3487 22.0929 36.6931 22.2685 36.9968C22.444 37.3004 22.6963 37.5526 23 37.7279L30 41.7279C30.304 41.9035 30.6489 41.9959 31 41.9959C31.3511 41.9959 31.696 41.9035 32 41.7279L39 37.7279C39.3037 37.5526 39.556 37.3004 39.7315 36.9968C39.9071 36.6931 39.9996 36.3487 40 35.9979Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5 24.2109L31 26.8109L35.5 24.2109"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5 39.79V34.6L22 32"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 32L35.5 34.6V39.79"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2656 26.9609L30.9956 32.0109L39.7256 26.9609"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 42.08V32"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMinD;
