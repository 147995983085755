import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './SliderCarousel.css';

import gsap from 'gsap';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { images } from '../../constants/Images';
import LazyImage from '../../utils/LazyImage';
import SplitType from 'split-type';

const SliderCarousel = ({ sliderCarouselRef }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const leftAnimationRef = useRef(null);
  const rightAnimationRef = useRef(null);
  const blockRef = useRef(null);
  const textAnimation = useRef(null);
  const textSubAnimation = useRef(null);

  useEffect(() => {
    // Устанавливаем начальный размер 0
    gsap.set(blockRef.current, {
      scale: 0,
    });

    // Запускаем анимацию увеличения размера
    gsap.to(blockRef.current, {
      scale: 1,
      duration: 1.5, // продолжительность анимации
      ease: 'power3.inOut', // тип плавности
    });
  }, []);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Устанавливаем начальное значение x для анимации справа
    gsap.set(leftAnimationRef.current, {
      opacity: 0, // Изначально элемент скрыт
      x: -1000, // Начальное положение справа (увеличь значение, если нужно, чтобы он начинался дальше)
    });

    // Анимация появления блока при скролле
    gsap.to(leftAnimationRef.current, {
      scrollTrigger: {
        trigger: leftAnimationRef.current, // Элемент, который должен анимироваться
        start: 'top 80%', // Когда элемент появляется в области видимости
        end: 'top 50%',
        toggleActions: 'play none none none',
      },
      opacity: 1, // Становится полностью видимым
      x: 0, // Возвращается на свое место
      duration: 1.5, // Длительность анимации
      ease: 'power3.out', // Плавность анимации
    });
  }, []);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Устанавливаем начальное значение x для анимации справа
    gsap.set(rightAnimationRef.current, {
      opacity: 0, // Изначально элемент скрыт
      x: 2000, // Начальное положение справа (увеличь значение, если нужно, чтобы он начинался дальше)
    });

    // Анимация появления блока при скролле
    gsap.to(rightAnimationRef.current, {
      scrollTrigger: {
        trigger: rightAnimationRef.current, // Элемент, который должен анимироваться
        start: 'top 80%', // Когда элемент появляется в области видимости
        end: 'top 50%',
        toggleActions: 'play none none none',
      },
      opacity: 1, // Становится полностью видимым
      x: 0, // Возвращается на свое место
      duration: 1.5, // Длительность анимации
      ease: 'power3.out', // Плавность анимации
    });
  }, []);
  useEffect(() => {
    const splitInstance = new SplitType(textAnimation.current, { types: 'chars' });

    gsap.from(splitInstance.chars, {
      duration: 1.5,
      opacity: 0,
      stagger: 0.05,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: textAnimation.current,
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
    });

    // Очищаем SplitType при размонтировании
    return () => splitInstance.revert();
  }, []);
  useEffect(() => {
    const splitInstance = new SplitType(textSubAnimation.current, { types: 'chars' });

    gsap.from(splitInstance.chars, {
      duration: 1.5,
      opacity: 0,
      stagger: 0.01,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: textSubAnimation.current,
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
    });

    // Очищаем SplitType при размонтировании
    return () => splitInstance.revert();
  }, []);

  return (
    <div ref={sliderCarouselRef} className="containerSlider">
      <div className={'relative top-[30px] '}>
        <div   className="wrapperTitleText">
          <div ref={textAnimation} className={`sliderTitle ${isVisible ? 'visibleLeft' : ''}`}>
            direction
          </div>
          <p
            ref={textSubAnimation}
            className="textCatousel"
          >
            AIXLAND breaks down every spatial and temporal barrier in real estate management, empowering you with real-time, precise monitoring and in-depth analysis of every operational process—anytime, anywhere
          </p>
        </div>
      </div>

      <Swiper
        ref={blockRef}
        slidesPerView={'auto'} // Display exactly 5 slides at a time
        lazyPreloadPrevNext={2} // Предзагружаем ближайшие два слайда
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true} // Включаем цикличность слайдов
        coverflowEffect={{
          rotate: 0,
          stretch: 30, // Немного уменьшаем расстояние между слайдами для наложения
          depth: 200, // Увеличиваем глубину для создания 3D-эффекта
          modifier: 1.5, // Более сильный эффект для увеличения главного слайда
          slideShadows: false, // Убираем тени для чистого вида
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        onSlideChange={swiper => setActiveIndex(swiper.realIndex)} // Update activeIndex on slide change
        modules={[EffectCoverflow, Pagination, Navigation]}
        className={`swiper_container ${isVisible ? 'visibleRight' : ''}`}
      >
        {images.map((image, index) => {
          let slideClass = 'other-slide'; // Базовый класс для всех слайдов

          // Логика для определения классов с учетом зацикливания
          if (index === activeIndex) {
            slideClass = 'central-slide';
          } else if (
            index ===
            (activeIndex - 1 + images.length) % images.length // Предыдущий слайд с учетом зацикливания
          ) {
            slideClass = 'prev-slide';
          } else if (
            index ===
            (activeIndex + 1) % images.length // Следующий слайд с учетом зацикливания
          ) {
            slideClass = 'next-slide';
          } else if (
            index ===
            (activeIndex - 2 + images.length) % images.length // Второй предыдущий слайд
          ) {
            slideClass = 'second-prev-slide';
          } else if (
            index ===
            (activeIndex + 2) % images.length // Второй следующий слайд
          ) {
            slideClass = 'second-next-slide';
          }

          return (
              <SwiperSlide key={image.id} className={slideClass}>
                   <img index={index} src={image.src} alt={image.alt} />
                 <div className="titleCarousel">{image.title}</div>
                {/*<div className="subtitleCarousel">{image.subtitle}</div>*/}
              </SwiperSlide>
           );
        })}
      </Swiper>
      {/*<div className={styles.mainGr}></div>*/}
    </div>
  );
};

export default SliderCarousel;
