import React, { useCallback, useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Block3D from '../../../assets/aboutSectionAssets/video/hexagonanim.webm';
import BlockIos from '../../../assets/aboutSectionAssets/video/hexagonAnim-1.mov';

import styles from './Block3DAbout.module.scss';

const Block3DAbout = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const videoRefAnimation = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Анимация появления блока при скролле
    gsap.to(videoRefAnimation.current, {
      scrollTrigger: {
        trigger: videoRefAnimation.current, // Элемент, который должен анимироваться
        start: 'top 80%', // Когда элемент появляется в области видимости
        end: 'top 50%',
        toggleActions: 'play none none none',
      },
      opacity: 1, // Становится полностью видимым
      x: 0, // Возвращается на свое место
      duration: 1, // Длительность анимации
      ease: 'power3.out', // Плавность анимации
    });
  }, []);

  const isIOS = () => {
    return (
      /iPhone|iPad|iPod/i.test(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };
  return (
    <section
      ref={videoRefAnimation}
      className={`${styles.videoBlock} ${isVisible ? styles.visibleVideo : ''}`}
    >
      <div className={`${styles.videoContainer} relative`}>
        <video playsInline muted autoPlay loop preload="metadata">
          {isIOS() ? (
            <source src={BlockIos} type="video/quicktime" />
          ) : (
            <source src={Block3D} type="video/webm" />
          )}
          Ваш браузер не поддерживает видео.
        </video>
      </div>
    </section>
  );
};

export default Block3DAbout;
