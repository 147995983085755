import React from 'react';
import styles from './index.module.scss';

const Group20873302632 = () => {
  return (
    <div>
      <svg className={styles.iconFill} width="186" height="160" viewBox="0 0 190 161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bi_2673_842)">
          <path
            d="M2.61914 81.0153L44.1768 8.72872C47.2804 3.33012 53.0327 0.00195702 59.2599 0.00195675L132.172 0.00195356C138.36 0.00195329 144.082 3.28856 147.2 8.63344L189.105 80.468L142.619 160.934L49.1046 160.934L2.61914 81.0153Z"
            fill="white" fillOpacity="0.1" />
          <path
            d="M49.4173 160.39L3.24719 81.0137L44.6481 8.9997C47.6548 3.76981 53.2273 0.545646 59.2599 0.545646L132.172 0.545643C138.166 0.545643 143.71 3.72954 146.73 8.90739L188.476 80.4693L142.305 160.39L49.4173 160.39Z"
            stroke="url(#paint0_linear_2673_842)" strokeOpacity="0.1" strokeWidth="1.08738" />
        </g>
        <circle cx="95.007" cy="78.2921" r="38.6019" stroke="white" strokeOpacity="0.1" strokeWidth="1.08738"
                strokeDasharray="2.17 2.17" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M83.6976 75.272C80.9122 74.2694 78.191 73.6497 75.4982 73.3434C75.4683 73.4583 75.4393 73.5736 75.4116 73.689L71.7949 75.081V80.9877L75.4116 82.3795C75.8755 84.3128 76.6369 86.1508 77.6779 87.8401L76.1016 91.3854L80.2773 95.5611L83.8225 93.9848C85.5118 95.0257 87.3498 95.7872 89.2772 96.2451L90.675 99.8677H96.5817L97.9736 96.2451C99.9009 95.7872 101.739 95.0257 103.428 93.9848L104.205 94.3302C104.234 94.3087 104.263 94.2879 104.293 94.2678L106.181 93.0034C104.584 90.6687 102.496 88.2442 100.189 85.9822C98.4063 87.4551 96.1191 88.3398 93.6254 88.3398C87.9328 88.3398 83.3229 83.7298 83.3229 78.0373C83.3229 77.0794 83.4534 76.152 83.6976 75.272ZM103.834 76.5954C106.766 78.6237 109.355 80.9312 111.56 83.4214C111.663 83.0775 111.756 82.7301 111.839 82.3795L115.456 80.9877V75.081L111.839 73.689C111.381 71.7618 110.614 69.9238 109.579 68.2344L111.149 64.6892L106.973 60.5135L103.428 62.0838C101.745 61.0429 99.9009 60.2815 97.9736 59.8235L96.5817 56.2069L90.675 56.2069L89.2831 59.8235C87.3498 60.2815 85.5118 61.0488 83.8225 62.0838L80.2773 60.5135L76.1016 64.6892L77.6779 68.2344C77.5493 68.4423 77.425 68.6526 77.3051 68.8651C80.6437 68.6374 84.1458 68.8797 87.6842 69.6138C89.3628 68.4265 91.4123 67.7288 93.6254 67.7288C98.8286 67.7288 103.132 71.5852 103.834 76.5954ZM114.17 89.5323C110.831 84.1391 105.78 79.2391 99.3653 75.6592C90.6353 70.7911 81.1531 69.3523 73.1465 71.0087C73.0037 71.0418 72.9075 71.1755 72.9215 71.3214C72.9354 71.4673 73.0553 71.5803 73.2018 71.5856C78.9109 71.8583 84.7005 73.4015 90.8854 76.8513C97.2259 80.3885 104.611 87.3774 108.338 93.4461L105.166 95.5709C105.013 95.6737 104.93 95.8542 104.953 96.0376C104.975 96.221 105.099 96.3762 105.272 96.4391L115.648 100.195C115.875 100.277 116.127 100.251 116.331 100.123C116.535 99.9952 116.67 99.7806 116.695 99.5409L117.926 87.9823C117.946 87.793 117.855 87.609 117.692 87.5106C117.529 87.4123 117.324 87.4172 117.166 87.5233L114.17 89.5323Z"
              fill="white" />
        <circle cx="2.17476" cy="2.17476" r="2.17476" transform="matrix(-1 0 0 1 4.88086 78.291)" fill="#AEAEAE" />
        <defs>
          <filter id="filter0_bi_2673_842" x="-51.7498" y="-54.367" width="295.224" height="269.67"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1845" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2673_842" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2673_842" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.08738" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2673_842" />
          </filter>
          <linearGradient id="paint0_linear_2673_842" x1="4.2509" y1="0.00194862" x2="123.043" y2="255.933"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>

    </div>
  );
};

export default Group20873302632;