import React from 'react';
import styles from './index.module.scss';

const Group2087330263 = () => {
  return (
    <div>
      <svg className={styles.iconFill} width="186" height="160" viewBox="0 0 184 164" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bi_2673_893)">
          <path
            d="M0.105469 82.0981L46.0472 2.17579L127.864 2.17578C134.091 2.17578 139.844 5.50429 142.948 10.9033L178.917 73.4776C181.988 78.8192 182.004 85.3864 178.96 90.7432L137.842 163.108H46.5017L0.105469 82.0981Z"
            fill="white" fillOpacity="0.1" />
          <path
            d="M46.8169 162.564L0.732296 82.0986L46.3618 2.71947L127.864 2.71947C133.897 2.71947 139.47 5.94396 142.476 11.1743L178.446 73.7485C181.42 78.9232 181.436 85.2852 178.487 90.4746L137.525 162.564H46.8169Z"
            stroke="url(#paint0_linear_2673_893)" strokeOpacity="0.1" strokeWidth="1.08738" />
        </g>
        <circle cx="2.17476" cy="2.17476" r="2.17476" transform="matrix(-1 0 0 1 47.9492 0)" fill="#AEAEAE" />
        <circle cx="91.2198" cy="83.0792" r="38.6019" stroke="white" strokeOpacity="0.1" strokeWidth="1.08738"
                strokeDasharray="2.17 2.17" />
        <path d="M86.964 57H75.609V63.9242L86.964 62.0317V57Z" fill="white" />
        <path
          d="M111.962 104.067V71.2847H93.4648V104.067H90.5352V64.4067L72.0379 67.4896V104.067H67V106.996H117V104.067H111.962ZM104.729 75.4973H107.841V78.427H104.729V75.4973ZM104.729 82.6396H107.841V85.5693H104.729V82.6396ZM104.729 89.7819H107.841V92.7116H104.729V89.7819ZM104.729 96.9243H107.841V99.854H104.729V96.9243ZM97.5864 75.4973H100.698V78.427H97.5864V75.4973ZM97.5864 82.6396H100.698V85.5693H97.5864V82.6396ZM97.5864 89.7819H100.698V92.7116H97.5864V89.7819ZM97.5864 96.9243H100.698V99.854H97.5864V96.9243ZM83.3018 68.355H86.4136V71.2847H83.3018V68.355ZM83.3018 75.4973H86.4136V78.427H83.3018V75.4973ZM83.3018 82.6396H86.4136V85.5693H83.3018V82.6396ZM83.3018 89.7819H86.4136V92.7116H83.3018V89.7819ZM76.1595 75.4973H79.2713V78.427H76.1595V75.4973ZM76.1595 82.6396H79.2713V85.5693H76.1595V82.6396ZM76.1595 89.7819H79.2713V92.7116H76.1595V89.7819ZM76.2505 96.9243H86.3226V104.067H83.3929V99.854H79.1802V104.067H76.2505V96.9243Z"
          fill="white" />
        <defs>
          <filter id="filter0_bi_2673_893" x="-54.2635" y="-52.1931" width="289.865" height="269.67"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1845" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2673_893" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2673_893" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.08738" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2673_893" />
          </filter>
          <linearGradient id="paint0_linear_2673_893" x1="1.71344" y1="2.17578" x2="121.629" y2="256.762"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>

    </div>
  );
};

export default Group2087330263;