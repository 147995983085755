import React from 'react';
import styles from './index.module.scss';

const Group2087330261 = () => {
  return (
    <div>
      <svg className={styles.iconFill}  width="186" height="160" viewBox="0 0 188 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bi_2673_899)">
          <path
            d="M3.04688 79.9223L49.2605 4.03395e-06L141.688 0L187.901 79.9223L146.712 151.156C143.603 156.533 137.862 159.845 131.651 159.845H59.2976C53.086 159.845 47.3455 156.533 44.2362 151.156L3.04688 79.9223Z"
            fill="white" fillOpacity="0.1" />
          <path
            d="M44.7068 150.883L3.67491 79.9223L49.5741 0.543693L141.374 0.543689L187.273 79.9223L146.241 150.883C143.229 156.093 137.668 159.301 131.651 159.301H59.2976C53.2801 159.301 47.719 156.093 44.7068 150.883Z"
            stroke="url(#paint0_linear_2673_899)" strokeOpacity="0.1" strokeWidth="1.08738" />
        </g>
        <circle cx="2.17476" cy="2.17476" r="2.17476" transform="matrix(-1 0 0 1 5.22266 77.207)" fill="#AEAEAE" />
        <circle cx="98.2589" cy="77.6808" r="38.6019" stroke="white" strokeOpacity="0.1" strokeWidth="1.08738"
                strokeDasharray="2.17 2.17" />
        <path
          d="M109.832 70.2109C102.562 70.2109 96.6484 76.1246 96.6484 83.3943C96.6484 90.6641 102.562 96.5777 109.832 96.5777C117.102 96.5777 123.113 90.6641 123.113 83.3943C123.113 76.1246 117.102 70.2109 109.832 70.2109ZM111.335 90.4538C111.323 90.4585 111.309 90.4563 111.297 90.4607V92.1833C111.297 92.993 110.641 93.6481 109.832 93.6481C109.022 93.6481 108.367 92.993 108.367 92.1833V90.4508C107.402 90.1248 106.478 89.5062 105.688 88.5642C105.167 87.9447 105.247 87.0206 105.868 86.5014C106.487 85.9807 107.414 86.0607 107.931 86.6816C108.706 87.6043 109.583 87.9705 110.332 87.7016C110.909 87.4913 111.297 86.9377 111.297 86.324C111.297 85.5158 110.64 84.8592 109.832 84.8592C107.408 84.8592 105.437 82.8879 105.437 80.4647C105.437 78.6952 106.49 77.1059 108.12 76.4165C108.2 76.3823 108.286 76.3843 108.367 76.3552V74.6055C108.367 73.7958 109.022 73.1406 109.832 73.1406C110.642 73.1406 111.297 73.7958 111.297 74.6055V76.3578C112.055 76.6141 112.795 77.0214 113.455 77.661C114.036 78.2232 114.05 79.1502 113.487 79.7324C112.925 80.3132 111.996 80.3261 111.415 79.7639C110.703 79.0729 109.915 78.8354 109.262 79.1145C108.719 79.3447 108.367 79.874 108.367 80.4648C108.367 81.273 109.024 81.9297 109.832 81.9297C112.255 81.9297 114.226 83.901 114.226 86.3241C114.226 88.165 113.065 89.8244 111.335 90.4538Z"
          fill="white" />
        <path
          d="M74.5781 84.8594C73.7685 84.8594 73.1133 85.5145 73.1133 86.3242V101.07C73.1133 101.88 73.7685 102.535 74.5781 102.535H78.9726V84.8594H74.5781Z"
          fill="white" />
        <path
          d="M107.855 61.6782L97.6009 52.8892C97.0544 52.4171 96.2419 52.4171 95.6954 52.8892L85.4417 61.6782C84.9754 62.0758 84.808 62.7224 85.0211 63.2975C85.2328 63.8726 85.7806 64.2545 86.3944 64.2545H90.7888C90.7888 76.9185 90.7888 89.8706 90.7888 102.535C94.5625 102.535 97.1714 102.535 101.043 102.535C101.852 102.535 102.507 101.88 102.507 101.07V97.7292C97.2983 95.0565 93.7185 89.6415 93.7185 83.395C93.7185 77.1486 97.2984 71.7334 102.507 69.0607V64.2546H106.902C107.516 64.2546 108.064 63.8727 108.275 63.2976C108.488 62.7224 108.321 62.0758 107.855 61.6782Z"
          fill="white" />
        <path
          d="M83.4648 73.1406C82.6552 73.1406 82 73.7958 82 74.6055V102.535C84.1396 102.535 85.7818 102.535 87.8593 102.535V73.1406H83.4648Z"
          fill="white" />
        <defs>
          <filter id="filter0_bi_2673_899" x="-51.3221" y="-54.3689" width="293.591" height="268.582"
                  filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1845" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2673_899" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2673_899" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="1.08738" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2673_899" />
          </filter>
          <linearGradient id="paint0_linear_2673_899" x1="4.66436" y1="-4.50664e-06" x2="124.129" y2="255.13"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>


    </div>
  );
};

export default Group2087330261;