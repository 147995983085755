import React, { useCallback, useEffect, useRef } from 'react';

import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

import styles from './AboutUsSection.module.scss';
import InfoBlockWithDots from './InfoBlockWithDots';

import Block3DAbout from './Block3DAbout/Block3DAbout';
import SplitType from 'split-type';
import BorderLine from '../Technology/BorderLine';
const AboutUsSection = ({ aboutUsRef }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  // const aboutRefAnimation = useRef(null);
  const aboutRefAnimation = useRef(null);
  const textAnimation = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(aboutRefAnimation.current, {
      scrollTrigger: {
        trigger: aboutRefAnimation.current,
        start: 'top 80%',
        end: 'top 50%',
        toggleActions: 'play none none none',
      },
      opacity: 1,
      x: 0,
      duration: 1,
      ease: 'power3.out',
    });
  }, []);

  useEffect(() => {
    const splitInstance = new SplitType(textAnimation.current, { types: 'chars' });

    gsap.from(splitInstance.chars, {
      duration: 1.5,
      opacity: 0,
      stagger: 0.10,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: textAnimation.current,
        start: 'top 75%',
        toggleActions: 'play none none none',
      },
    });

    // Очищаем SplitType при размонтировании
    return () => splitInstance.revert();
  }, []);


  return (
    <div ref={aboutUsRef} className={` ${styles.aboutUsSection} `}>
      <section
        ref={aboutRefAnimation}
        className={`${styles.wrapperAbout} ${isVisible ? styles.visibleAbout : ''}`}
      >
        <header className={styles.header}>
          <h1 ref={textAnimation}>Who We Are</h1>
        </header>
        <div className={styles.mainContent}></div>
        <InfoBlockWithDots />
      </section>
      <Block3DAbout />
    </div>
  );
};

export default AboutUsSection;
