import React from 'react';

const LogoWhite = () => {
  return (
    <svg width="102" height="27" viewBox="0 0 102 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_872_58978)">
        <path
          d="M90.4447 13.4998H82.7388L91.802 0.908203L81.5937 0.908203C79.9112 0.908203 78.3396 1.70719 77.3717 3.04835L71.6347 11.0211L65.8977 3.04835C64.9356 1.70909 63.3563 0.908203 61.6815 0.908203L51.0993 0.908203L51.8978 1.99064L58.8325 11.4035C59.7984 12.7199 61.3583 13.4998 63.0155 13.4998H69.8509L61.962 24.4631C61.7049 24.8207 61.1635 24.8207 60.9065 24.4631L59.6484 22.711L45.4947 3.05025C44.5346 1.70909 42.9553 0.908203 41.2786 0.908203L31.0625 0.908203L47.6446 23.9514C48.6105 25.2925 50.1841 26.0915 51.8666 26.0915L71.0018 26.0915C72.6766 26.0915 74.2559 25.2925 75.216 23.9514L81.3152 15.4745L87.5937 23.9951C88.5596 25.3115 90.1195 26.0915 91.7767 26.0915H102.18L94.6589 15.64C93.6988 14.2988 92.1194 13.4998 90.4447 13.4998Z"
          fill="white" />
        <path
          d="M24.8099 3.04834C23.8498 1.70719 22.2705 0.908203 20.5957 0.908203L10.3797 0.908203L19.4429 13.5017L11.7351 13.5017C10.0603 13.5017 8.48094 14.3007 7.52087 15.6419L0 26.0934H10.403C12.0583 26.0934 13.6182 25.3134 14.5861 23.997L20.8645 15.4745L26.9637 23.9494C27.9297 25.2906 29.5032 26.0896 31.1857 26.0896L41.3882 26.0896L40.2217 24.465L24.8099 3.04834Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_872_58978">
          <rect width="102" height="25.1852" fill="white" transform="translate(0 0.908203)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default LogoWhite;